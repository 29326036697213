import React, { useRef } from 'react';
import '../styles/Offer.css';
import { Link } from 'react-router-dom';

const Offer = () => {
    const pricingTableRef = useRef(null);
   

   

    return (
        <div className="offer-website-package-section">
           
           <section className="offer-web-package">
            <h2 className="offer-website-package-heading">Website Packages</h2>
            <p className="offer-website-package-description">Choose a package that suits your needs.</p>
            <div className="offer-scrollable-container">
                <div className="offer-pricing-table" ref={pricingTableRef}>
                    {/* Basic Plan */}
                    <div className="offer-pricing-card">
                        <div className="offer-card-header">
                            <svg
                                className="offer-wavy-background"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 1440 420"
                                width="180%"
                                height="300"
                            >
                                <defs>
                                    <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                                        <stop offset="0%" style={{ stopColor: '#F67280', stopOpacity: 1 }} />
                                        <stop offset="100%" style={{ stopColor: '#7D0552', stopOpacity: 1 }} />
                                    </linearGradient>
                                </defs>
                                <path
                                    fill="url(#gradient)"
                                    fillOpacity="1"
                                    d="M0,96L48,117.3C96,139,192,181,288,176C384,171,480,117,576,128C672,139,768,213,864,256C960,299,1056,309,1152,293.3C1248,277,1344,235,1392,213.3L1440,192L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
                                ></path>
                            </svg>
                            <div className="offer-card-title">Basic Plan</div>
                            <div className="offer-pricing-circle">₹20,000</div>
                        </div>
                        <div className="offer-features-section">
                            <div className="offer-feature-list">
                                {/* Features for Basic Plan */}
                                <div className="offer-feature-item">
                                    <span className="offer-feature-name">Responsive Design</span>
                                    <div className="offer-status-circle offer-tick">✔</div>
                                </div>
                                <div className="offer-feature-item">
                                    <span className="offer-feature-name">Number of Web Pages</span>
                                    <div className="offer-status-circle offer-tick">5</div>
                                </div>
                                <div className="offer-feature-item">
                                    <span className="offer-feature-name">Basic SEO Optimization</span>
                                    <div className="offer-status-circle offer-cross">✖</div>
                                </div>
                                <div className="offer-feature-item">
                                    <span className="offer-feature-name">Free Domain Registration</span>
                                    <div className="offer-status-circle offer-cross">✖</div>
                                </div>
                                <div className="offer-feature-item">
                                    <span className="offer-feature-name">Free Hosting</span>
                                    <div className="offer-status-circle offer-tick">✔</div>
                                </div>
                                <div className="offer-feature-item">
                                    <span className="offer-feature-name">Support After Delivery</span>
                                    <div className="offer-status-circle offer-tick">1w</div>
                                </div>
                                <div className="offer-feature-item">
                                    <span className="offer-feature-name">Number of Revisions</span>
                                    <div className="offer-status-circle offer-tick">1</div>
                                </div>
                                <div className="offer-feature-item">
                                    <span className="offer-feature-name">SSL Certificate</span>
                                    <div className="offer-status-circle offer-cross">✖</div>
                                </div>
                                <div className="offer-feature-item">
                                    <span className="offer-feature-name">Customization Options</span>
                                    <div className="offer-status-circle offer-tick">✔</div>
                                </div>
                                <button className="offer-card-button"> <Link to="/contact" state={{ fromOffer: true }} style={{ textDecoration: 'none', color: 'white' }}>Inq. Now</Link></button>
                            </div>
                        </div>
                    </div>

                    {/* Standard Plan */}
                    <div className="offer-pricing-card">
                        <div className="offer-card-header">
                            <svg
                                className="offer-wavy-background"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 1440 420"
                                width="180%"
                                height="300"
                            >
                                <defs>
                                    <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                                        <stop offset="0%" style={{ stopColor: '#F1C40F', stopOpacity: 1 }} />
                                        <stop offset="100%" style={{ stopColor: '#D35400', stopOpacity: 1 }} />
                                    </linearGradient>
                                </defs>
                                <path
                                    fill="url(#gradient)"
                                    fillOpacity="1"
                                    d="M0,96L48,117.3C96,139,192,181,288,176C384,171,480,117,576,128C672,139,768,213,864,256C960,299,1056,309,1152,293.3C1248,277,1344,235,1392,213.3L1440,192L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
                                ></path>
                            </svg>
                            <div className="offer-card-title">Standard Plan</div>
                            <div className="offer-pricing-circle">₹35,000</div>
                        </div>
                        <div className="offer-features-section">
                            <div className="offer-feature-list">
                                {/* Features for Standard Plan */}
                                <div className="offer-feature-item">
                                    <span className="offer-feature-name">Responsive Design</span>
                                    <div className="offer-status-circle offer-tick">✔</div>
                                </div>
                                <div className="offer-feature-item">
                                    <span className="offer-feature-name">Number of Web Pages</span>
                                    <div className="offer-status-circle offer-tick">10</div>
                                </div>
                                <div className="offer-feature-item">
                                    <span className="offer-feature-name">Basic SEO Optimization</span>
                                    <div className="offer-status-circle offer-tick">✔</div>
                                </div>
                                <div className="offer-feature-item">
                                    <span className="offer-feature-name">Free Domain Registration</span>
                                    <div className="offer-status-circle offer-tick">✔</div>
                                </div>
                                <div className="offer-feature-item">
                                    <span className="offer-feature-name">Free Hosting</span>
                                    <div className="offer-status-circle offer-tick">✔</div>
                                </div>
                                <div className="offer-feature-item">
                                    <span className="offer-feature-name">Support After Delivery</span>
                                    <div className="offer-status-circle offer-tick">1m</div>
                                </div>
                                <div className="offer-feature-item">
                                    <span className="offer-feature-name">Number of Revisions</span>
                                    <div className="offer-status-circle offer-tick">2</div>
                                </div>
                                <div className="offer-feature-item">
                                    <span className="offer-feature-name">SSL Certificate</span>
                                    <div className="offer-status-circle offer-tick">✔</div>
                                </div>
                                <div className="offer-feature-item">
                                    <span className="offer-feature-name">Limit Customization Options</span>
                                    <div className="offer-status-circle offer-tick">✔</div>
                                </div>
                                <button className="offer-card-button"> <Link to="/contact" state={{ fromOffer: true }} style={{ textDecoration: 'none', color: 'white' }}>Inq. Now</Link></button>
                            </div>
                        </div>
                    </div>

                    {/* Premium Plan */}
                    <div className="offer-pricing-card">
                        <div className="offer-card-header">
                            <svg
                                className="offer-wavy-background"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 1440 420"
                                width="180%"
                                height="300"
                            >
                                <defs>
                                    <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                                        <stop offset="0%" style={{ stopColor: '#2980B9', stopOpacity: 1 }} />
                                        <stop offset="100%" style={{ stopColor: '#8E44AD', stopOpacity: 1 }} />
                                    </linearGradient>
                                </defs>
                                <path
                                    fill="url(#gradient)"
                                    fillOpacity="1"
                                    d="M0,96L48,117.3C96,139,192,181,288,176C384,171,480,117,576,128C672,139,768,213,864,256C960,299,1056,309,1152,293.3C1248,277,1344,235,1392,213.3L1440,192L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
                                ></path>
                            </svg>
                            <div className="offer-card-title">Premium Plan</div>
                            <div className="offer-pricing-circle">₹50,000</div>
                        </div>
                        <div className="offer-features-section">
                            <div className="offer-feature-list">
                                {/* Features for Premium Plan */}
                                <div className="offer-feature-item">
                                    <span className="offer-feature-name">Responsive Design</span>
                                    <div className="offer-status-circle offer-tick">✔</div>
                                </div>
                                <div className="offer-feature-item">
                                    <span className="offer-feature-name">Number of Web Pages</span>
                                    <div className="offer-status-circle offer-tick">15</div>
                                </div>
                                <div className="offer-feature-item">
                                    <span className="offer-feature-name">Basic SEO Optimization</span>
                                    <div className="offer-status-circle offer-tick">✔</div>
                                </div>
                                <div className="offer-feature-item">
                                    <span className="offer-feature-name">Free Domain Registration</span>
                                    <div className="offer-status-circle offer-tick">✔</div>
                                </div>
                                <div className="offer-feature-item">
                                    <span className="offer-feature-name">Free Hosting</span>
                                    <div className="offer-status-circle offer-tick">✔</div>
                                </div>
                                <div className="offer-feature-item">
                                    <span className="offer-feature-name">Support After Delivery</span>
                                    <div className="offer-status-circle offer-tick">6m</div>
                                </div>
                                <div className="offer-feature-item">
                                    <span className="offer-feature-name">Number of Revisions</span>
                                    <div className="offer-status-circle offer-tick">5</div>
                                </div>
                                <div className="offer-feature-item">
                                    <span className="offer-feature-name">SSL Certificate</span>
                                    <div className="offer-status-circle offer-tick">✔</div>
                                </div>
                                <div className="offer-feature-item">
                                    <span className="offer-feature-name">Fully Customizable Options</span>
                                    <div className="offer-status-circle offer-tick">✔</div>
                                </div>
                                <button className="offer-card-button"> <Link to="/contact" state={{ fromOffer: true }} style={{ textDecoration: 'none', color: 'white' }}>Inq. Now</Link></button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
           </section>
           
<section className='offer-mobile-package'>
  <h2 className="offer-package-heading">Mobile App Packages</h2>
  <p className="offer-package-description">Choose a package that suits your needs.</p>
  <div className="offer-scrollable-container">
    <div className="offer-pricing-table" ref={pricingTableRef}>
      
      {/* Basic Plan */}
      <div className="offer-pricing-card">
        <div className="offer-card-header">
          <svg
            className="offer-wavy-background"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 420"
            width="180%"
            height="300"
          >
            <defs>
              <linearGradient id="mp-gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" style={{ stopColor: '#F67280', stopOpacity: 1 }} />
                <stop offset="100%" style={{ stopColor: '#7D0552', stopOpacity: 1 }} />
              </linearGradient>
            </defs>
            <path
              fill="url(#mp-gradient)"
              fillOpacity="1"
              d="M0,96L48,117.3C96,139,192,181,288,176C384,171,480,117,576,128C672,139,768,213,864,256C960,299,1056,309,1152,293.3C1248,277,1344,235,1392,213.3L1440,192L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
            ></path>
          </svg>
          <div className="offer-card-title">Basic Plan</div>
          <div className="offer-pricing-circle">₹25,000</div>
        </div>
        <div className="offer-features-section">
          <div className="offer-feature-list">
            <div className="offer-feature-item">
              <span className="offer-feature-name">Responsive Design</span>
              <div className="offer-status-circle offer-tick">✔</div>
            </div>
            <div className="offer-feature-item">
              <span className="offer-feature-name">Number of Screens</span>
              <div className="offer-status-circle offer-tick">5</div>
            </div>
            <div className="offer-feature-item">
              <span className="offer-feature-name">Basic App Features</span>
              <div className="offer-status-circle offer-tick">✔</div>
            </div>
            <div className="offer-feature-item">
              <span className="offer-feature-name">App Store Submission</span>
              <div className="offer-status-circle offer-cross">✖</div>
            </div>
            <div className="offer-feature-item">
              <span className="offer-feature-name">Free Maintenance</span>
              <div className="offer-status-circle offer-tick">1w</div>
            </div>
            <div className="offer-feature-item">
              <span className="offer-feature-name">Number of Revisions</span>
              <div className="offer-status-circle offer-tick">1</div>
            </div>
            <button className="offer-card-button"> <Link to="/contact" state={{ fromOffer: true }} style={{ textDecoration: 'none', color: 'white' }}>Inq. Now</Link></button>
          </div>
        </div>
      </div>

      {/* Standard Plan */}
      <div className="offer-pricing-card">
        <div className="offer-card-header">
          <svg
            className="offer-wavy-background"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 420"
            width="180%"
            height="300"
          >
            <defs>
              <linearGradient id="lp-gradient" x1="0%" y1="0%" x2="100%">
                <stop offset="0%" style={{ stopColor: '#F1C40F', stopOpacity: 1 }} />
                <stop offset="100%" style={{ stopColor: '#D35400', stopOpacity: 1 }} />
              </linearGradient>
            </defs>
            <path
              fill="url(#lp-gradient)"
              fillOpacity="1"
              d="M0,96L48,117.3C96,139,192,181,288,176C384,171,480,117,576,128C672,139,768,213,864,256C960,299,1056,309,1152,293.3C1248,277,1344,235,1392,213.3L1440,192L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
            ></path>
          </svg>
          <div className="offer-card-title">Standard Plan</div>
          <div className="offer-pricing-circle">₹50,000</div>
        </div>
        <div className="offer-features-section">
          <div className="offer-feature-list">
            <div className="offer-feature-item">
              <span className="offer-feature-name">Responsive Design</span>
              <div className="offer-status-circle offer-tick">✔</div>
            </div>
            <div className="offer-feature-item">
              <span className="offer-feature-name">Number of Screens</span>
              <div className="offer-status-circle offer-tick">10</div>
            </div>
            <div className="offer-feature-item">
              <span className="offer-feature-name">Basic SEO Optimization</span>
              <div className="offer-status-circle offer-tick">✔</div>
            </div>
            <div className="offer-feature-item">
              <span className="offer-feature-name">App Store Submission</span>
              <div className="offer-status-circle offer-tick">✔</div>
            </div>
            <div className="offer-feature-item">
              <span className="offer-feature-name">Free Maintenance</span>
              <div className="offer-status-circle offer-tick">1m</div>
            </div>
            <div className="offer-feature-item">
              <span className="offer-feature-name">Number of Revisions</span>
              <div className="offer-status-circle offer-tick">2</div>
            </div>
            <button className="offer-card-button"> <Link to="/contact" state={{ fromOffer: true }} style={{ textDecoration: 'none', color: 'white' }}>Inq. Now</Link></button>
          </div>
        </div>
      </div>

      {/* Premium Plan */}
      <div className="offer-pricing-card">
        <div className="offer-card-header">
          <svg
            className="offer-wavy-background"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 420"
            width="180%"
            height="300"
          >
            <defs>
              <linearGradient id="ap-gradient" x1="0%" y1="0%" x2="100%">
                <stop offset="0%" style={{ stopColor: '#2980B9', stopOpacity: 1 }} />
                <stop offset="100%" style={{ stopColor: '#8E44AD', stopOpacity: 1 }} />
              </linearGradient>
            </defs>
            <path
              fill="url(#ap-gradient)"
              fillOpacity="1"
              d="M0,96L48,117.3C96,139,192,181,288,176C384,171,480,117,576,128C672,139,768,213,864,256C960,299,1056,309,1152,293.3C1248,277,1344,235,1392,213.3L1440,192L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
            ></path>
          </svg>
          <div className="offer-card-title">Premium Plan</div>
          <div className="offer-pricing-circle">₹1,00,000</div>
        </div>
        <div className="offer-features-section">
          <div className="offer-feature-list">
            <div className="offer-feature-item">
              <span className="offer-feature-name">Responsive Design</span>
              <div className="offer-status-circle offer-tick">✔</div>
            </div>
            <div className="offer-feature-item">
              <span className="offer-feature-name">Number of Screens</span>
              <div className="offer-status-circle offer-tick">15</div>
            </div>
            <div className="offer-feature-item">
              <span className="offer-feature-name">Full SEO Optimization</span>
              <div className="offer-status-circle offer-tick">✔</div>
            </div>
            <div className="offer-feature-item">
              <span className="offer-feature-name">App Store Submission</span>
              <div className="offer-status-circle offer-tick">✔</div>
            </div>
            <div className="offer-feature-item">
              <span className="offer-feature-name">Free Maintenance</span>
              <div className="offer-status-circle offer-tick">6m</div>
            </div>
            <div className="offer-feature-item">
              <span className="offer-feature-name">Number of Revisions</span>
              <div className="offer-status-circle offer-tick">7</div>
            </div>
            <button className="offer-card-button"> <Link to="/contact" state={{ fromOffer: true }} style={{ textDecoration: 'none', color: 'white' }}>Inq. Now</Link></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

 <section className='offer-whatsapp-automation-package'>
  <h2 className="offer-package-heading">WhatsApp Automation Packages</h2>
  <p className="offer-package-description">Choose the perfect plan to automate and streamline your WhatsApp communication efficiently.</p>
  <div className="offer-scrollable-container">
    <div className="offer-pricing-table" ref={pricingTableRef}>
      {/* Basic Plan */}
      <div className="offer-pricing-card">
        <div className="offer-card-header">
          <svg className="offer-wavy-background" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 420" width="180%" height="300">
            <defs>
              <linearGradient id="gradient-basic" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" style={{ stopColor: '#00008B', stopOpacity: 1 }} />
                <stop offset="100%" style={{ stopColor: '#0AFFFF', stopOpacity: 1 }} />
              </linearGradient>
            </defs>
            <path fill="url(#gradient-basic)" fillOpacity="1" d="M0,96L48,117.3C96,139,192,181,288,176C384,171,480,117,576,128C672,139,768,213,864,256C960,299,1056,309,1152,293.3C1248,277,1344,235,1392,213.3L1440,192L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path>
          </svg>
          <div className="offer-card-title">Basic Plan</div>
          <div className="offer-pricing-circle">₹500/m</div>
        </div>
        <div className="offer-features-section">
          <div className="offer-feature-list">
            <div className="offer-feature-item">
              <span className="offer-feature-name">100 Messages/Day</span>
              <div className="offer-status-circle offer-tick">✔</div>
            </div>
            <div className="offer-feature-item">
              <span className="offer-feature-name">Basic Automation</span>
              <div className="offer-status-circle offer-tick">✔</div>
            </div>
            <div className="offer-feature-item">
              <span className="offer-feature-name">1 User License</span>
              <div className="offer-status-circle offer-tick">✔</div>
            </div>
            <div className="offer-feature-item">
              <span className="offer-feature-name">Customer Support (Email)</span>
              <div className="offer-status-circle offer-tick">✔</div>
            </div>
            <button className="offer-card-button"> <Link to="/contact" state={{ fromOffer: true }} style={{ textDecoration: 'none', color: 'white' }}>Inq. Now</Link></button>
          </div>
        </div>
      </div>

      {/* Standard Plan */}
      <div className="offer-pricing-card">
        <div className="offer-card-header">
          <svg className="offer-wavy-background" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 420" width="180%" height="300">
            <defs>
              <linearGradient id="gradient-standard" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" style={{ stopColor: '#483C32', stopOpacity: 1 }} />
                <stop offset="100%" style={{ stopColor: '#ffbe76', stopOpacity: 1 }} />
              </linearGradient>
            </defs>
            <path fill="url(#gradient-standard)" fillOpacity="1" d="M0,96L48,117.3C96,139,192,181,288,176C384,171,480,117,576,128C672,139,768,213,864,256C960,299,1056,309,1152,293.3C1248,277,1344,235,1392,213.3L1440,192L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path>
          </svg>
          <div className="offer-card-title">Standard Plan</div>
          <div className="offer-pricing-circle">₹800/m</div>
        </div>
        <div className="offer-features-section">
          <div className="offer-feature-list">
            <div className="offer-feature-item">
              <span className="offer-feature-name">500 Messages/Day</span>
              <div className="offer-status-circle offer-tick">✔</div>
            </div>
            <div className="offer-feature-item">
              <span className="offer-feature-name">Advanced Automation</span>
              <div className="offer-status-circle offer-tick">✔</div>
            </div>
            <div className="offer-feature-item">
              <span className="offer-feature-name">3 User Licenses</span>
              <div className="offer-status-circle offer-tick">✔</div>
            </div>
            <div className="offer-feature-item">
              <span className="offer-feature-name">Customer Support (Phone & Email)</span>
              <div className="offer-status-circle offer-tick">✔</div>
            </div>
            <button className="offer-card-button"> <Link to="/contact" state={{ fromOffer: true }} style={{ textDecoration: 'none', color: 'white' }}>Inq. Now</Link></button>
          </div>
        </div>
      </div>

      {/* Premium Plan */}
      <div className="offer-pricing-card">
        <div className="offer-card-header">
          <svg className="offer-wavy-background" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 420" width="180%" height="300">
            <defs>
              <linearGradient id="gradient-premium" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" style={{ stopColor: '#F67280', stopOpacity: 1 }} />
                <stop offset="100%" style={{ stopColor: '#ff7979', stopOpacity: 1 }} />
              </linearGradient>
            </defs>
            <path fill="url(#gradient-premium)" fillOpacity="1" d="M0,96L48,117.3C96,139,192,181,288,176C384,171,480,117,576,128C672,139,768,213,864,256C960,299,1056,309,1152,293.3C1248,277,1344,235,1392,213.3L1440,192L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path>
          </svg>
          <div className="offer-card-title">Premium Plan</div>
          <div className="offer-pricing-circle">₹1,800/m</div>
        </div>
        <div className="offer-features-section">
          <div className="offer-feature-list">
            <div className="offer-feature-item">
              <span className="offer-feature-name">Unlimited Messages</span>
              <div className="offer-status-circle offer-tick">✔</div>
            </div>
            <div className="offer-feature-item">
              <span className="offer-feature-name">Full Automation & Custom Integration</span>
              <div className="offer-status-circle offer-tick">✔</div>
            </div>
            <div className="offer-feature-item">
              <span className="offer-feature-name">5+ User Licenses</span>
              <div className="offer-status-circle offer-tick">✔</div>
            </div>
            <div className="offer-feature-item">
              <span className="offer-feature-name">Dedicated Customer Support</span>
              <div className="offer-status-circle offer-tick">✔</div>
            </div>
            <button className="offer-card-button"> <Link to="/contact" state={{ fromOffer: true }} style={{ textDecoration: 'none', color: 'white' }}>Inq. Now</Link></button>
          </div>
        </div>
      </div>
    </div>


  </div>
</section>




        </div>

        
    );
};

export default Offer;
